<template>
    <mnSup mostrar="true"/>
    <main id="buscador">
        <h1>Buscador</h1>
        <section class="filtros wrap">
            <form>
                <div class="boxItem">
                    <!-- <label for="">Región</label> -->
                    <div class="formItem inputSelect">
                        <select name="region" v-model="b.region" @change="this.filterData()">
                            <option selected value="">Todas las Regiones</option>
                            <option v-for="(region, index) in selectData.region" :key="'r'+index" :value="region.nombre">{{region.nombre}}</option>
                        </select>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div class="formItem inputCheckox">
                    <!-- <label for="">categoría</label> -->
                    <input type="checkbox" name="innovacion" id="" v-model="b.innovacion" @change="this.filterData()"> <span>Innovación</span><br>
                    <input type="checkbox" name="investigacion" id="" v-model="b.investigacion" @change="this.filterData()"> <span>Investigación</span>
                </div>
                <div class="formItem inputCheckox">
                    <!-- <label for="">Nivel</label> -->
                    <input type="checkbox" name="innovacion" id="" v-model="b.basica" @change="this.filterData()"> <span>Básica</span><br>
                    <input type="checkbox" name="investigacion" id="" v-model="b.media" @change="this.filterData()"> <span>Media</span>
                </div>
                <div class="boxItem">
                    <!-- <label for="">OCDE</label> -->
                    <div class="formItem inputSelect">
                        <select name="" id="" v-model="b.ocde" @change="this.filterData()">
                            <option selected value="">Todas las Disciplinas</option>
                            <option value="Ciencias Agrícolas">Ciencias Agrícolas</option>
                            <option value="Ciencias Médicas y de la Salud">Ciencias Médicas y de la Salud</option>
                            <option value="Ciencias Naturales">Ciencias Naturales</option>
                            <option value="Ciencias Sociales">Ciencias Sociales</option>
                            <option value="Ingeniería y Tecnología">Ingeniería y Tecnología</option>
                        </select>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
            </form>
        </section>
        
        <section class="listProyectos wrap">
            <ul class="table">
                <li class="thead">
                    <span>Código</span>
                    <span>Nombre Proyecto</span>
                    <span>Región</span>
                    <span>Nivel</span>
                    <span>Categoría</span>
                    <span>Disciplina</span>
                </li>
                <li class="row" v-for="(pr, index) in prList" :key="'pr'+index">
                    <span>{{pr.codigo}}</span>
                    <span>{{pr.titulo}}</span>
                    <span>{{pr.PAR}}</span>
                    <span>{{pr.nivel}}</span>
                    <span>{{pr.categoria}}</span>
                    <span>{{pr.disciplina_OCDE}}</span>
                </li>
            </ul>
        </section>
       
    </main>
    <footerLogo/>
</template>

<script>
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import {mapState, mapMutations} from 'vuex';

export default {
    name:'buscar',
    components:{
        mnSup, footerLogo
    },
    data(){
        return {
            data:'',
            prList:'',
            b:{
                region:'',
                innovacion:true,
                investigacion:true,
                basica:true,
                media:true,
                ocde:''
            },
            selectData:{
                region:'',
                ocde:''
            }
        }
    },
    created(){
        document.title = 'BUSCADOR - XXI Congreso Nacional Explora'
        this.loadRegion()
        this.loadProyectos()
    },
    methods:{
        async loadProyectos(){

            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getProyectFiltered&parametros={"filters":[]}`;
            console.log('url',url);

            try{
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);
                this.data = data.salida;
                
                this.filterData();

            }catch(e){
                console.log(e);
            }
        },
        async loadRegion(){
            this.selectData.region = null;
            let url = `/bdd/regiones.json`;
            try{
                let rs = await fetch(url);
                let data = await rs.json();
                data = data.salida.sort((a,b)=>a.codigo-b.codigo);

                this.selectData.region = data;
               
            }catch(e){
                console.log(e);
            }
        },
        filterData(){
            let proyectos = this.data;
            
            if(this.b.region!=''){
                proyectos = proyectos.filter((pr)=>{
                    return pr.PAR == this.b.region;
                })
            }
            if(!this.b.innovacion){
                proyectos = proyectos.filter((pr)=>{
                    return pr.categoria != 'Innovación';
                })
            }
            if(!this.b.investigacion){
                proyectos = proyectos.filter((pr)=>{
                    return pr.categoria != 'Investigación CN' && pr.categoria != 'Investigación CS';
                })
            }

            if(!this.b.basica){
                proyectos = proyectos.filter((pr)=>{
                    return pr.nivel != 'Básica';
                })
            }
            if(!this.b.media){
                proyectos = proyectos.filter((pr)=>{
                    return pr.nivel != 'Media';
                })
            }
            if(this.b.ocde!=''){
                proyectos = proyectos.filter((pr)=>{
                    return pr.disciplina_OCDE == this.b.ocde;
                })
            }
            

            this.prList = proyectos;
        }
    },
    computed:{
        ...mapState(['apiURL']),
        
    }
}
</script>

<style lang="scss" scoped>
main#buscador{
    padding: 30px 0px;
    background-image: url('../../public/assets/img/buscador-fondo.svg');
    h1{
        text-align: center;
        padding:20px;
    }
    section.filtros{
        background: #000;
        color:#fff;
        border-radius: 50px;
        padding:10px 50px;
        margin-bottom:30px;
        form{
            padding: 0px;
            display: grid;
            grid-template-columns: 1fr 220px 180px 1fr;
            gap:40px;
            .boxItem{
                margin-bottom: 0px;
                align-self: center;
                .inputSelect{
                    border-radius: 50px;
                    overflow: hidden;
                }
            }
        }
    }
    section.listProyectos{
        background: #fff;
        color:#000;
        border:2px solid #000;
        border-radius: 20px;
        padding:20px;
        margin-bottom:30px;
        ul.table{
            li{
                list-style: none;
                display: grid;
                grid-template-columns: 140px 1fr 150px 80px 100px 130px;
                gap:10px;
                &.thead{
                    font-weight: 900;
                    font-size: 1.1em;
                    margin-bottom:20px;
                    padding:5px;
                }
                &.row{
                    min-height: 50px;
                    padding:5px;
                    &:nth-child(even){
                        background-color: #f9f6f0;
                    }
                    a{
                        color:#000;
                        font-weight: 500;
                        &:hover{
                            color:var(--color2);
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
footer{
    position: relative;
}
</style>